<!-- <div *ngIf="!hasNavbarHeaders()" slBootstrapNavbarRendered class="navbar--hidden placeholder"></div> -->

<ng-container *ngIf="hasNavbarHeaders()">
   <!-- NOTE: Keep user observable even though it's not use. The observable is tapped to set user context for logging -->
  <ng-container *ngIf="{
    user: user$ | async,
    company: company$ | async,
    allowNavigation: allowNavigation$ | async, 
    itemBadges: itemBadges$ | async } as sharedPipes">
<nav id="sidebar" [class.active]="active" [class.disabled]="!sharedPipes.allowNavigation">
  <!-- BRAND -->
  <div class="sidebar-header" id="sidebarCollapse" (click)="toggleSidebar()">
      <div class="s_brand-expanded" ></div>
      <div class="s_brand-collapsed"></div>
  </div>

  <ng-container *ngIf="showCompanyName()">
    <div class="s_mnu-header s_mnu-company">
      <ng-container *ngIf="sharedPipes.company">
        {{ sharedPipes.company.name }}  
      </ng-container>
      <ng-container *ngIf="!sharedPipes.company">
        <span style="color:transparent">_</span>
      </ng-container>
    </div>
  </ng-container>

  <!-- Navbar Groups -->
  <ul class="list-unstyled components">
    
    <ng-container *ngFor="let navbarGroup of navbarGroups">
      <li *ngIf="hasGroupTitle(navbarGroup)" class="s_mnu-header">
        {{ navbarGroup.title }}
      </li>
      <!-- SPACER -->
      <li class="s_mnu-hr">
        <hr>
      </li>

      <!-- Navbar Headers -->
      <li *ngFor="let navbarHeader of getNavbarHeaders(navbarGroup.id)" >

        <ng-container *ngIf="hasHeaderLink(navbarHeader)">
            <a [routerLink]="[navbarHeader.link]" class="nav-link" [class.active]="isHeaderActive(navbarHeader.id)" [attr.id]="navbarHeader.elementId">
              <ins></ins> 
              <span class="nav-item">{{ navbarHeader.title }}</span>
          
              <span *ngIf="itemBadgeValue(navbarHeader.id, sharedPipes.itemBadges) != null" class="badge badge-light float-right" >{{ itemBadgeValue(navbarHeader.id, sharedPipes.itemBadges) }}</span>
            </a>
        </ng-container>

        <ng-container *ngIf="!hasHeaderLink(navbarHeader)">
            <a style="cursor:pointer"  [attr.id]="navbarHeader.elementId"  (click)="$event.stopPropagation(); $event.preventDefault(); navbarHeader.expanded = !navbarHeader.expanded;"
            class="dropdown-toggle" [class]="{ active: isActive(navbarHeader.id), collapsed: !isActive(navbarHeader.id) }"
            >
                <ins></ins> 
                <span class="nav-item">{{ navbarHeader.title }}</span>
               
            </a>
  
            <!-- Navbar Items -->
            <ul class="list-unstyled collapse" [isAnimated]="true" [collapse]="!navbarHeader.expanded && !isActive(navbarHeader.id)" [attr.id]="getChildId(navbarHeader.elementId)">
              <ng-container *ngFor="let navbarSubItem of getNavbarItems(navbarHeader.id)">
                <li *ngIf="!isDivider(navbarSubItem)" >
                  <a [id]='getSubItemId(navbarSubItem.link)' [routerLink]="[navbarSubItem.link]"   class="nav-subitem" [class.active]="isActive(navbarSubItem.id)">
                    {{ navbarSubItem.title }}
                  </a>
                 
                </li>
                <li *ngIf="isDivider(navbarSubItem)" class="s_sub-hr">
                    <hr>
                </li>
            </ng-container>              
            </ul>
        </ng-container>
      </li>
    </ng-container>

  </ul>

</nav>
</ng-container>
</ng-container>