import { FileOperationInfo, FilePreviewRequest, FilePreviewResponse, IdName, ObjectHistory, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';

export class SafariObjectList<T> {
  items: T[];
  totalCount: number;
  params?: string;
  id: SafariObjectId;
  countOnly?: boolean;

  static empty<T>(id: SafariObjectId = null): SafariObjectList<T> {
    return {
      totalCount: 0,
      items: [],
      id
    };
  }
}
export interface ISafariObjectState<T> {
  list: {
    /**
     * Lists that are requested to be loaded
     */
    loading: SafariObjectList<T>[];
    /**
     * Lists that were succesfully loaded but not being viewed yet
     */
    loaded: SafariObjectList<T>[];
    /**
     * Lists that are requested to be saved
     */
    saving: SafariObjectList<T>[];
    /**
     * Lists that were successfully saved
     */
    saved: SafariObjectList<T>[];
    /**
     * Lists requested to be deleted
     */
    deleting: SafariObjectList<T>[];
    /**
     * Lists that were successfully saved
     */
    deleted: SafariObjectList<T>[];

    /**
     * Lists that are seen by the UI (usually a swap from loaded or saved into this property)
     */

    current: SafariObjectList<T>[];

    /**
     * Lists that failed to load
     */
    failedToLoad: SafariObjectList<T>[];
    /**
     * Lists that failed to save
     */
    failedToSave: SafariObjectList<T>[];
    /**
     * Lists that failed to delete
     */
    failedToDelete: SafariObjectList<T>[];
    /**
     * Lists that failed to swap from saved or loaded into current
     */
    failedToRefresh: SafariObjectList<T>[];
  };
  object: {
    /**
     * Objects that are requested to be loaded
     */
    loading: T[];
    /**
     * Objects that were succesfully loaded but not being viewed yet
     */
    loaded: T[];
    /**
     * Objects that are requested to be saved
     */
    saving: T[];
    /**
     * Objects that were successfully saved
     */
    saved: T[];
    /**
     * Objects requested to be deleted
     */
    deleting: T[];
    /**
     * Objects that were successfully saved
     */
    deleted: T[];

    /**
     * Objects that are seen by the UI (usually a swap from loaded or saved into this property)
     */

    current: T[];

    /**
     * Objects that failed to load
     */
    failedToLoad: T[];
    /**
     * Objects that failed to save
     */
    failedToSave: T[];
    /**
     * Objects that failed to delete
     */
    failedToDelete: T[];
    /**
     * Objects that failed to swap from saved or loaded into current
     */
    failedToRefresh: T[];
  };

  auditHistory: {
    /**
     * Objects that are requested to be loaded
     */
    loading: ObjectHistory[];
    /**
     * Objects that were succesfully loaded but not being viewed yet
     */
    loaded: ObjectHistory[];
    /**
     * Objects that are seen by the UI (usually a swap from loaded or saved into this property)
     */
    current: ObjectHistory[];
    /**
     * Objects that failed to load
     */
    failedToLoad: ObjectHistory[];
  };
}
export interface IFileUploadState {
  files: FileOperationInfo[];
  filePreview: FilePreviewRequest;
  filePreviewResponse: FilePreviewResponse;
}

// empty interfaces are generally not a good idea but in TS, but in this case it's ok since we want to have
// a declaration similar to other state interfaces. In reality its just an alias with string:IdName[] key/val pairs
// Same for search except we are using for the property type any.
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- comments above
export interface IDropdownState extends Record<string, IdName[]> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- comments above
export interface ISearchState extends Record<string, any> {}
