import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
// Import errorhandlerservice directly rather than via barrel file. Errorhandler is using the loggerservice which in turn
// imports elmahIO it causes errors in testsuites (gives a very generic "unknown property src" error but it's related
// to importing elmahio from node_modules)
import { NavbarPermissionsService, NavbarService, SharedAppBootstrapModule } from '@safarilaw-webapp/shared/app-bootstrap';
import { AuthInterceptorService, AuthService, UserVerificationService } from '@safarilaw-webapp/shared/auth';

import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ErrorDialogComponent, ErrorHandlerService, HttpErrorInterceptorService } from '@safarilaw-webapp/shared/error-handling';
import { SafariDefaultReuseStategy } from '@safarilaw-webapp/shared/routing-utility';
import { BlockUIModule } from 'ng-block-ui';
import { environment } from '../environments/environment';

import { ModalDialogService } from '@safarilaw-webapp/shared/dialog';
import { LoggerService } from '@safarilaw-webapp/shared/logging';

import { catchError, Observable, tap, throwError } from 'rxjs';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// this seems to be a bug in lint parser. It thinks we are importing the whole library and throws 'Static imports of lazy-loaded libraries are forbidden' error.
// But we're not importing the whole library, just the definittion of the base PageNotFoundComponent, which is a standalone index file.
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { GATEWAY_TIMEOUT_URLS } from '@safarilaw-webapp/common-pages/gateway-timeout';
// this seems to be a bug in lint parser. It thinks we are importing the whole library and throws 'Static imports of lazy-loaded libraries are forbidden' error.
// But we're not importing the whole library, just the definittion of the base PageNotFoundComponent, which is a standalone index file.
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { SUPPORT_EMAIL } from '@safarilaw-webapp/common-pages/page-error';
import { LpmsAuthService, LpmsHeaderComponent, LpmsNavbarComponent, LpmsNavbarService, LpmsUserVerificationService } from '@safarilaw-webapp/feature/lpms/shared';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { AppUpdateSplashComponent } from './app-update-splash/components/app-update-splash/app-update-splash';
import { environmentImports } from './app.module.env.imports';
import { AppRoutingModule } from './app.routing';
import { LpmsConfigurationService } from './configuration/lpms-configuration.service';
import { LpmsNavbarPermissionsService } from './navbar/services/lpms-navbar-permissions/lpms-navbar-permissions.service';
import { MaintenanceHeaderComponent } from './site-maintenance/components/maintenance-header/maintenance-header';
import { MaintenanceSplashComponent } from './site-maintenance/components/maintenance-splash/maintenance-splash';
export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: NgxCurrencyInputMode.Financial
};

// DUPE: initialAppConfigFactory
// We must make sure to run this before anything else. AppConfigurationService needs to
// be constructed and any overrides need to be applied before the app starts. Since overrides use
// http to retrieve we can not do this in the constructor (constructor can't be blocked until http resolves),
// However by using initializer we can block until after overrideConfiguration finishes.
const initialAppConfigFactory =
  (appConfigService: AppConfigurationService, loggerService: LoggerService): (() => Observable<boolean>) =>
  (): Observable<boolean> =>
    appConfigService.overrideConfiguration().pipe(
      tap(() => loggerService.initializeElmahLogging()),
      catchError(err => {
        // It looks like at some point we may have dropped the code that used to render errors that happened
        // during INIT (probably when we got rid of IE11 support). These are not very likely to happen but if
        // they do they'd result in a blank page. For now just alert, but maybe we should open a story to
        // bring that error element back. (it'd probably be a DIV in index.html that gets shown via direct
        // DOM statements here)
        alert(err);
        return throwError(() => err);
      })
    );

@NgModule({
  declarations: [AppUpdateSplashComponent, AppComponent, LpmsNavbarComponent, LpmsHeaderComponent, MaintenanceSplashComponent, MaintenanceHeaderComponent],
  imports: [
    BlockUIModule.forRoot(),

    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    SharedAppBootstrapModule,

    AppRoutingModule,
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production || environment.nonprod, registrationStrategy: 'registerImmediately' }),
    [...environmentImports]
  ],
  providers: [
    provideEnvironmentNgxCurrency(customCurrencyMaskConfig),

    { provide: UserVerificationService, useClass: LpmsUserVerificationService },
    {
      provide: AuthService,
      useClass: LpmsAuthService
    },
    {
      provide: NavbarService,
      useClass: LpmsNavbarService
    },
    {
      provide: GATEWAY_TIMEOUT_URLS,
      useValue: 'safarilaw.com / *.safarilaw.com'
    },

    {
      provide: SUPPORT_EMAIL,
      useValue: 'support@safarisop.com'
    },

    { provide: APP_INITIALIZER, useFactory: initialAppConfigFactory, deps: [AppConfigurationService, LoggerService], multi: true },
    {
      provide: AppConfigurationService,
      useClass: LpmsConfigurationService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: NavbarPermissionsService,
      useClass: LpmsNavbarPermissionsService
    },
    // NAVBAR CHANGE - comment out the block below
    {
      provide: RouteReuseStrategy,
      useClass: SafariDefaultReuseStategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(dialogService: ModalDialogService) {
    // Register error dialog component so we can send it via
    // showDialog
    dialogService.registerComponent(ErrorDialogComponent.ClassId, ErrorDialogComponent);
  }
}
