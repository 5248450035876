<ng-container
    *ngIf="{
        navbarActive: navbarActive$ | async
    } as sharedObservables">
    
    <header class="navbar">
        <div class="container s_con">
            <!-- ### Use s_container-wide for tables / remove for forms -->
            <div class="mr-auto" id="s_navleft">

                <button type="button"
                    class="navbar-toggler" [class.collapsed]="!sharedObservables.navbarActive"
                    (click)="toggleSidebar()"
                >
                    <span> </span>
                    <span> </span>
                    <span> </span>
                </button>                    
       
                <!-- TITLE-->
                <div class="s_con-title float-left">
                   {{  title }}
                </div><!-- END: s_con-title-->
                <!-- END: #s_view -->
               
            </div><!-- END: #s_navleft -->
            <div id="s_navright">
                <ng-template #viewContainerRef></ng-template>
                <!-- HELP -->
                 @if(isAuthenticated()) {
         

                    <div id="s_help" class="dropdown float-left" dropdown>
                        <a class="dropdown-toggle"  id="s_hlp-link" dropdownToggle  role="button">
                            <span class="s_encircle"></span>
                        </a>
                         
                        <ul id="s_hlp-content" class="dropdown-menu dropdown-menu-right s_account"  *dropdownMenu >
                            <li role="menuitem"><div class="s_dropdown-header">Safari Support</div></li>
                            <li role="menuitem"><a target="_blank" id="s_hlp-center" class="dropdown-item" (click)="helpCenterClicked()" href="https://help.safarilaw.com/portal/en/kb/safari-law">Help Center</a></li>
                            <li role="menuitem"> <a [routerLink]="['/legal-process', 'support']" class="dropdown-item">Support</a></li>
                            <li role="menuitem"> <a target="_blank" id="s_hlp-livetraining" class="dropdown-item" (click)="liveTrainingClicked()" href="https://help.safarilaw.com/portal/en/kb/articles/safari-live-trainings">Live Training Sessions</a></li>
                        </ul> 
                    </div>
                 }

                 @if(currentUserInfo$ | async; as headerModel) {
                    <div id="s_account" class="dropdown float-left" dropdown >
                        <a class="dropdown-toggle"  id="s_account-link" dropdownToggle role="button" >
                            <span class="s_encircle">{{headerModel.initials}}</span>
                        </a>
                        
                        <ul id="s_account-content" class="dropdown-menu dropdown-menu-right s_account"  *dropdownMenu>
                            @if(isAuthenticated()) {
                                <li role="menuitem"> 
                                <div  
                                class ="pr-3 text-break whitespace-normal s_dropdown-header" 
                                 >
                                <span>Hello, {{headerModel.firstName}} {{headerModel.lastName}}</span>
                                <br>
                                <span id="s_account-email">{{headerModel.email}}</span>
                            
                            </div>
                                </li>
                                <li role="menuitem"> 
                            <a  id="s_account-profile" 
                                class="dropdown-item" 
                                  
                                [routerLink]="['/settings/cousers/edit', 'me']">
                                My Profile
                            </a>
                                </li>
                                <li role="menuitem">
                            <a  id="s_account-terms" 
                                class="dropdown-item"
                                 
                                [routerLink]="['/termsofservice']">
                                Terms &amp; Privacy
                            </a>
                                </li>
                            }
           
                            <li role="menuitem">
                            <a id="s_account-select-company" 
                                [routerLink]="['/select-company']"
                                type="a"
                                class="dropdown-item" 
                                *ngIf="showChangeCompanyMenuItem"
                              >
                                Change Company
                            </a>
                            </li>
                            @if(isAuthenticated()) {
                                <li role="menuitem">
                                <a id="s_account-logout" 
                                [routerLink]="['/logout']"
                                type="a"
                                class="dropdown-item" 
                                
                              >
                                Sign Out
                            </a>
                                </li>
                            }
                            
                        </ul>
                    </div>
                 }

            </div>
        </div>
    </header>
     
    </ng-container>